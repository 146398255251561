<!--
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2023-02-06 09:07:28
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2024-08-02 17:34:27
 * @FilePath: /com.huizhouyiren.B0805/src/views/login/Login.vue
 * @Description: 登录页
-->
<template>
  <div class="flex items-center justify-center w-full h-full duration-500 login"
    :style="{ background: `url(${getLogo.background}) no-repeat` }">
    <div class="relative flex items-center justify-center inner_box">
      <div class="absolute top-0 left-0 right-0 w-full p-4 duration-500 logo">
        <!-- <img src="../../assets/images/login/logo.png" alt class="duration-500" /> -->
        <img class="duration-500 logo" :src="getLogo.login" alt @click="goPage('/')" />
      </div>

      <div class="relative z-10 flex items-center justify-center duration-500 bg-white rounded-lg login_box">
        <div class="p-4">
          <!-- <div @click="pageType = 'login'">登录</div> -->
          <div class="form_box" v-if="pageType == 'login'">
            <div class="flex items-center justify-center">
              <p class="sm:text-lg form_title cursor" :class="loginType == 'pwd' ? 'active' : ''"
                @click="loginType = 'pwd'">
                账号密码登录
              </p>
              <p style="height: .625rem; width: .125rem" class="mx-4 bg-gray-300"></p>
              <p class="sm:text-lg form_title cursor" :class="loginType == 'vail' ? 'active' : ''"
                @click="loginType = 'vail'">
                手机验证码登录
              </p>
            </div>
            <div class="mt-4" v-show="loginType == 'pwd'">
              <div class="flex items-center justify-center px-4 mb-2 rounded input_box">
                <input type="text" v-model="userName" placeholder="用户名" class="w-full sm:text-sm" />
              </div>
              <div class="flex items-center justify-center px-4 rounded input_box">
                <input type="password" v-model="passWord" placeholder="密码为8-16个字符" class="w-full sm:text-sm" />
              </div>
            </div>
            <div class="mt-4" v-show="loginType == 'vail'">
              <div class="flex items-center justify-center px-4 mb-2 rounded input_box">
                <input type="text" placeholder="手机号码" v-model="telphone" class="w-full sm:text-sm" />
              </div>
              <div class="relative flex items-center justify-center px-4 rounded input_box">
                <input type="number" placeholder="验证码" v-model="smsCode" maxlength="6" class="w-full sm:text-sm" />
                <div class="absolute top-0 right-0 flex items-center justify-center bg-white rounded vail text-primary"
                  @click="getCode">
                  {{ tip }}
                </div>
              </div>
            </div>
            <div class="flex items-center justify-center mt-4 text-white rounded bg-primary sm:text-base btn cursor"
              @click="loginCheckingRule">
              立即登录
            </div>
            <div class="flex justify-end mt-2">
              <div class="flex items-center">
                <p class="text-gray-500 duration-500 sm:text-sm cursor hover:text-primary"
                  @click="pageType = 'register'">
                  注册新账号
                </p>
                <p style="height: .5rem; width: .125rem" class="mx-4 bg-gray-300"></p>
                <p class="text-gray-500 duration-500 sm:text-sm cursor hover:text-primary" @click="pageType = 'found'">
                  忘记密码
                </p>
              </div>
            </div>
            <div class="flex items-center mt-4">
              <div class="pr-2 text-gray-600">其他方式登录</div>
              <div class="flex items-center justify-center pr-1">
                <img class="way_img cursor" src="../../assets/images/login/chat_active.png" alt
                  @click="wechatGetCode" />
              </div>
              <div class="flex items-center justify-center">
                <img class="way_img cursor" id="qqLoginBtn" src="../../assets/images/login/qq_active.png" alt
                  @click="qqLogin" />
              </div>
            </div>
          </div>

          <div class="form_box" v-if="pageType == 'register'">
            <div class="flex items-center justify-center">
              <p class="sm:text-lg form_title cursor" :class="registerType == 'pwd' ? 'active' : ''"
                @click="registerType = 'pwd'">
                用户名注册
              </p>
              <p style="height: .625rem; width: .125rem" class="mx-4 bg-gray-300"></p>
              <p class="sm:text-lg form_title cursor" :class="registerType == 'vail' ? 'active' : ''"
                @click="registerType = 'vail'">
                手机验证码注册
              </p>
            </div>
            <div class="mt-4">
              <div class="flex items-center justify-center px-4 mb-2 rounded input_box">
                <input v-if="registerType == 'pwd'" type="text" placeholder="用户名" v-model="userName"
                  class="w-full sm:text-sm" />
                <input v-if="registerType == 'vail'" type="text" placeholder="手机号码" v-model="telphone"
                  class="w-full sm:text-sm" />
              </div>

              <div v-show="registerType == 'pwd'" class="flex items-center justify-center px-4 mb-2 rounded input_box">
                <input type="password" v-model="passWord" placeholder="密码为8-16个数字字母组合" class="w-full sm:text-sm" />
              </div>
              <div v-show="registerType == 'vail'"
                class="relative flex items-center justify-center px-4 mb-2 rounded input_box">
                <input type="number" v-model="smsCode" placeholder="验证码" maxlength="6" class="w-full sm:text-sm" />
                <div @click="getCode"
                  class="absolute top-0 right-0 flex items-center justify-center bg-white rounded cursor-pointer vail text-primary">
                  {{ tip }}
                </div>
              </div>
              <!-- <div class="flex items-center justify-between px-4 mb-2 rounded input_box">
                <span>我的出生日期是？</span>
                <span class="text-gray-400">(个人中心可修改)</span>
              </div>
              <div class="flex items-center justify-between px-4 mb-2 rounded input_box">
                <input type="text" placeholder="密保问题答案（可选填）" v-model="secretAnswer" class="w-full sm:text-sm" />
              </div>
              <div class="relative flex items-center px-4 mb-2 rounded input_box">
                <div class="text-gray-400">您的性别（可选填）</div>
                <div class="absolute right-0 flex" style="top: -0.0625rem;">
                  <div class="flex items-center justify-center mr-2 rounded select_box cursor" @click="sex = 1"
                    :class="sex == 1 ? 'select_box_active' : ''">男</div>
                  <div class="flex items-center justify-center rounded select_box none_border cursor" @click="sex = 2"
                    :class="sex == 2 ? 'select_box_active' : ''">女</div>
                </div>
              </div>
              <div class="flex items-center justify-between mb-2 rounded">
                <div class="w-full pc_select_show">
                  <el-cascader size="large" :options="options" v-model="selectedOptions" @change="handleChange"
                    class="form_input" placeholder="您的现居住地（可选填）"></el-cascader>
                </div>
                <div class="w-full phone_select_show">
                  <div class="flex items-center px-4 rounded input_box" @click="drawer = true">
                    <span class="text-gray-400">您的现居住地（可选填）</span>
                  </div>
                </div>
              </div>
              <div class="relative flex items-center px-4 mb-2 rounded input_box">
                      <div class="text-gray-400">已购设备绑定</div>
                      <div class="absolute right-0 flex" style="top: -0.0625rem;">
                        <div class="flex items-center justify-center mr-2 rounded select_box cursor" @click="isBind = true"
                          :class="isBind ? 'select_box_active' : ''">是</div>
                        <div class="flex items-center justify-center rounded select_box none_border cursor"
                          @click="isBind = false" :class="isBind ? '' : 'select_box_active'">否</div>
                      </div>
                    </div> -->
            </div>
            <div class="flex items-center text-sm cursor-pointer">
              <span style="height:.875rem;width: .875rem;border:.0625rem solid #cccc;box-sizing: border-box; "
                class="flex items-center justify-center mr-1 rounded" @click="agress = !agress">
                <span style="height:.5rem;width: .5rem;display: inline-block;border-radius: .125rem;" class="bg-primary"
                  v-if="agress"></span>
              </span>
              <span class="text-gray-400">我已阅读并接受</span>
              <span class="cursor-pointer text-primary" @click="goAgreement(1)">用户协议</span>
              <span class="text-gray-400">和</span>
              <span class="cursor-pointer text-primary" @click="goAgreement(2)">隐私政策</span>
            </div>
            <div class="flex items-center justify-center mt-4 text-white rounded bg-primary sm:text-base btn cursor"
              @click="registerCheckingRule">
              立即注册
            </div>
            <div class="flex items-center justify-end mt-2">
              <span class="text-sm text-gray-500">已有账号，</span>
              <span class="text-sm cursor-pointer text-primary" @click="pageType = 'login'">去登录</span>
            </div>
          </div>
          <div class="form_box" v-if="pageType == 'found'">
            <div class="flex items-center justify-center">
              <p class="sm:text-lg form_title cursor" :class="foundType == 'smsCode' ? 'active' : ''"
                @click="foundType = 'smsCode'">
                手机号找回密码
              </p>
              <p style="height: .625rem; width: .125rem" class="mx-4 bg-gray-300"></p>
              <p class="sm:text-lg form_title cursor" :class="foundType == 'encrypted' ? 'active' : ''"
                @click="foundType = 'encrypted'">
                密保找回密码
              </p>
            </div>
            <div class="mt-4" v-show="foundType == 'smsCode'">
              <div class="flex items-center justify-center px-4 mb-2 rounded input_box">
                <input type="text" placeholder="手机号码" v-model="telphone" class="w-full sm:text-sm" />
              </div>
              <div class="relative flex items-center justify-center px-4 mb-2 rounded input_box">
                <input type="number" placeholder="验证码" v-model="smsCode" maxlength="6" class="w-full sm:text-sm" />
                <div @click="getCode"
                  class="absolute top-0 right-0 flex items-center justify-center bg-white rounded vail text-primary">
                  {{ tip }}
                </div>
              </div>
            </div>
            <div class="mt-4" v-show="foundType == 'encrypted'">
              <div class="flex items-center justify-center px-4 mb-2 rounded input_box">
                <input type="text" placeholder="用户名" v-model="userName" class="w-full sm:text-sm" />
              </div>
              <div class="flex items-center justify-between px-4 mb-2 rounded input_box">
                <span>我的出生日期是？</span>
              </div>
              <div class="flex items-center justify-center px-4 mb-2 rounded input_box">
                <input type="text" placeholder="密保问题答案" v-model="secretAnswer" class="w-full sm:text-sm" />
              </div>
            </div>
            <div>
              <div class="flex items-center justify-center px-4 mb-2 rounded input_box">
                <input type="text" placeholder="密码为8-16个字符" v-model="passWord" class="w-full sm:text-sm" />
              </div>
              <div class="flex items-center justify-center px-4 mb-2 rounded input_box">
                <input type="text" placeholder="再次输入密码" v-model="checkPassWord" class="w-full sm:text-sm" />
              </div>
            </div>
            <div class="flex items-center justify-center mt-4 text-white rounded bg-primary sm:text-base btn cursor"
              @click="foundCheckingRule">
              提交
            </div>
            <div class="flex justify-end mt-2 cursor-pointer text-primary" @click="pageType = 'login'">
              去登录
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer title :visible.sync="drawer" :direction="direction" :before-close="handleClose" :show-close="false"
      :withHeader="false" size="50%">
      <div style="height: 50vh;width: 100vw;">
        <v-distpicker type="mobile" @selected="selected"></v-distpicker>
      </div>
    </el-drawer>
    <!-- <div id="wx_qrcode"></div> -->
  </div>
</template>
<script>
// import axios from 'axios';
import { regionData } from "element-china-area-data";
import { mapGetters } from "vuex";
import { USER, PRIVACY } from "../../common/constant";
// import QC from 'qc'
export default {
  data() {
    return {
      loginType: "pwd", //登录方式   pwd 密码登录   vali  验证码
      registerType: "pwd", // 注册方式 pwd 密码注册   vali  验证码注册
      foundType: "smsCode", //  密码找回方式  smsCode 验证码   encrypted 密保
      pageType: "login", // 页面类型  login 登录  register 注册  found 找回密码
      sex: 1,
      userName: "",
      passWord: "",
      checkPassWord: "",
      smsCode: "",
      secretAnswer: "",
      isBind: true,
      agress: false,
      telphone: "",
      tip: "获取验证码",
      time: 0,
      timer: null,
      options: regionData,
      selectedOptions: [],
      drawer: false,
      direction: "btt",
      //省市区
      address: "",
      addressCode: [],
      code: "", // 第三方登录的code

      agreement: {
        1: USER,
        2: PRIVACY,
      },
    };
  },
  created() {
    this.pageType = this.$route.query.pageType
      ? this.$route.query.pageType
      : "login";
    if (this.getLoginStatus) {
      this.$EventBus.$emit("toast", {
        type: "error",
        message: "您已登录，请勿重新登录",
      });
      setTimeout(() => {
        this.$router.replace({ path: "/index" });
      }, 1000);
    }
    if (this.$route.query.code && this.$route.query.code != "") {
      this.pageType = "login";
      // 判断打开页面的设备是PC还是移动端
      if (this.browserRedirect()) {
        // let url = window.location.href
        // this.$EventBus.$emit("toast", { type: 'error', message: 'url=' + url })
        var params = this.getUrlParam("code");
        this.code = params;
        this.wechatLogin(5);
        // alert('返回的code', this.$route)
        // this.$EventBus.$emit("toast", { type: 'error', message: 'code=' + params })
      } else {
        this.code = this.$route.query.code;
        let loginFlag = localStorage.getItem("loginFlag");
        console.log("存储的loginFlag", loginFlag);
        if (loginFlag == "qq") {
          this.wechatLogin(5);
          // this.getQqToken()
        } else {
          this.wechatLogin(3);
        }
      }
    }
    // if (this.$route.query.access_token && this.$route.query.access_token != '') {
    //   console.logg('成功获取到token', this.$route.query.access_token)
    //   this.qqLoginFunc(this.$route.query.access_token)
    // }
  },
  mounted() {
    // 组件渲染完毕，使用QC生成QQ登录按钮
    // eslint-disable-next-line no-undef
    // QC.Login({
    //   btnId: 'qqLoginBtn'
    // })
  },
  watch: {
    pageType: function () {
      this.userName = "";
      this.passWord = "";
      this.checkPassWord = "";
      this.smsCode = "";
      this.secretAnswer = "";
      this.isBind = true;
      this.agress = false;
      this.telphone = "";
      this.tip = "获取验证码";
      this.time = 0;
      this.loginType = "pwd";
      this.registerType = "pwd";
      this.foundType = "smsCode";
      this.sex = 1;

      clearInterval(this.timer);
      this.timer = null;
    },
  },
  computed: {
    ...mapGetters(["getLogo", "getLoginStatus"]),
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    // 页面跳转
    goPage(path) {
      this.$router.push(path);
    },

    handleChange(value) {
      this.addressCode = [];
      this.addressCode.push(...value);
    },
    handleClose(done) {
      done();
    },
    // onChangeProvince1: function (a) {
    //   this.province = a.value;
    //   if (a.value == '台湾省') {
    //     this.drawer = false;
    //   }
    // },
    // onChangeCity: function (a) {
    //   this.city = a.value;
    // },
    // onChangeArea: function (a) {
    //   this.area = a.value;
    //   this.drawer = false;
    //   this.city = this.province + this.city + this.area;
    //   console.log(this.city)
    // },
    selected(e) {
      // console.log(e);
      this.address = e.province.value + "/" + e.city.value + "/" + e.area.value;
      this.addressCode = [];
      this.addressCode = [e.province.code, e.city.code, e.area.code];
      this.selectedOptions = [];
      this.selectedOptions = [e.province.code, e.city.code, e.area.code];
      this.drawer = false;
    },
    // 校验密码
    checkPwd(pwd) {
      var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
      var re = new RegExp(reg);
      if (re.test(pwd)) {
        return true;
      } else {
        return false;
      }
    },

    // 登录校验
    loginCheckingRule() {
      // console.log(this.passWord, this.isNull(this.passWord));
      if (this.loginType == "pwd") {
        if (this.isNull(this.userName)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: "用户名不能为空",
          });
          return;
        }

        if (this.isNull(this.passWord)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: "密码不能为空",
          });
          return;
        }
      } else {
        if (!this.checkPhone(this.telphone)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: "手机号码错误",
          });
          return;
        }
        if (this.isNull(this.smsCode)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: "验证码码不能为空",
          });
          return;
        }
      }
      this.login();
    },
    // 注册校验
    registerCheckingRule() {
      if (this.registerType == "pwd") {
        if (this.isNull(this.userName)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: "用户名不能为空",
          });
          return;
        }
        if (!this.checkPwd(this.passWord)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: "密码不符合规则，请重新设置",
          });
          return;
        }
      } else {
        if (!this.checkPhone(this.telphone)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: "手机号码错误",
          });
          return;
        }
        if (this.isNull(this.smsCode)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: "验证码码不能为空",
          });
          return;
        }
      }
      if (!this.agress) {
        this.$EventBus.$emit("toast", {
          type: "error",
          message: "请先同意相关协议和政策",
        });
        return;
      }

      this.register();
    },
    // 找回密码校验
    foundCheckingRule() {
      if (this.foundType == "smsCode") {
        if (!this.checkPhone(this.telphone)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: "手机号码错误",
          });
          return;
        }
        if (this.isNull(this.smsCode)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: "验证码不能为空",
          });
          return;
        }
      } else {
        if (this.isNull(this.userName)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: "用户名不能为空",
          });
          return;
        }
        if (this.isNull(this.secretAnswer)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: "密保问题答案不能为空",
          });
          return;
        }
      }
      if (!this.checkPwd(this.passWord)) {
        this.$EventBus.$emit("toast", {
          type: "error",
          message: "密码不符合规则，请重新设置",
        });
        return;
      }
      if (this.passWord !== this.checkPassWord) {
        this.$EventBus.$emit("toast", {
          type: "error",
          message: "两次密码不一致，请修改",
        });
        return;
      }
      this.foundType == "smsCode"
        ? this.smsCodeFoundPwd()
        : this.encryptedDFoundPwd();
    },
    // 登录
    login() {
      let data = {};
      if (this.loginType == "pwd") {
        data.passWord = this.passWord;
        data.userName = this.userName;
        data.loginType = 1;
      } else {
        data.smsCode = this.smsCode;
        data.telphone = this.telphone;
        data.loginType = 2;
      }
      this.$request({
        method: "post",
        url: this.$requestPath.login,
        data,
      })
        .then((res) => {
          if (res.code == 0) {
            // this.$message({
            //   message: '登录成功',
            //   type: 'success'
            // });
            this.afterLogin(res.data);
          } else {
            // this.$message.error(res.msg);
            // this.$toast("success", res.msg)
            this.$EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("获取验证码error", error);
        });
    },
    // 注册
    register() {
      let data = {
        cityId: 0,
        isBind: this.isBind,
        secretAnswer: this.secretAnswer,
        sex: this.sex,
      };
      if (this.registerType == "pwd") {
        data.passWord = this.passWord;
        data.userName = this.userName;
      } else {
        data.smsCode = this.smsCode;
        data.telphone = this.telphone;
      }
      this.addressCode.length > 0
        ? (data.cityId = this.addressCode.join(","))
        : "";
      // console.log('data', data)
      this.$request({
        method: "post",
        url: this.$requestPath.register,
        data: data,
      })
        .then((res) => {
          console.log("注册", res);
          if (res.code == 0) {
            // this.$message({
            //   message: '注册成功,请重新登录',
            //   type: 'success'
            // });
            this.$EventBus.$emit("toast", {
              type: "success",
              message: "注册成功,请重新登录",
            });
            setTimeout(() => {
              this.pageType = "login";
            }, 1000);
          } else {
            // this.$message.error(res.msg);
            this.$EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("获取验证码error", error);
        });
    },
    // 获取验证码
    getCode() {
      if (!this.checkPhone(this.telphone)) {
        // this.$message.error('手机号码错误');
        this.$EventBus.$emit("toast", {
          type: "error",
          message: "手机号码错误",
        });
        return;
      }
      if (this.time > 0) {
        // this.$message(`${this.time}s后可重新获取`);
        this.$EventBus.$emit("toast", {
          type: "info",
          message: `${this.time}s后可重新获取`,
        });
        return;
      }

      this.$request({
        method: "post",
        url: this.$requestPath.getCode,
        data: {
          smsCodeType:
            this.pageType == "login" ? 2 : this.pageType == "found" ? 3 : 1,
          telphone: this.telphone,
        },
      })
        .then((res) => {
          console.log("获取验证码res", res);
          if (res.code == 0) {
            // this.$message({
            //   message: '验证码发送成功',
            //   type: 'success'
            // });
            this.$EventBus.$emit("toast", {
              type: "success",
              message: "验证码发送成功",
            });
            this.time = 60;
            this.timer = setInterval(() => {
              if (this.time == 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.tip = "获取验证码";
              } else {
                this.time = this.time - 1;
                this.tip = this.time + "s";
              }
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log("获取验证码error", error);
        });
    },

    // 手机验证码找回密码
    smsCodeFoundPwd() {
      this.$request({
        method: "post",
        url: this.$requestPath.smsCodeFoundPwd,
        data: {
          telphone: this.telphone,
          smsCode: this.smsCode,
          passWord: this.passWord,
          // newPassword: this.checkPassWord,
          // loginType: 2
        },
      })
        .then((res) => {
          if (res.code == 0) {
            // this.$message({
            //   message: '密码重置成功,请重新登录',
            //   type: 'success'
            // });
            this.$EventBus.$emit("toast", {
              type: "success",
              message: "密码重置成功,请重新登录",
            });
            setTimeout(() => {
              this.pageType = "login";
            }, 1000);
          } else {
            // this.$message.error(res.msg);
            this.$EventBus.$emit("toast", {
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch((error) => {
          console.log("手机验证码找回密码", error);
        });
    },
    // 密保问题找回验证码
    encryptedDFoundPwd() {
      this.$request({
        method: "post",
        url: this.$requestPath.encryptedDFoundPwd,
        data: {
          userName: this.userName,
          secretAnswer: this.secretAnswer,
          passWord: this.passWord,
        },
      })
        .then((res) => {
          if (res.code == 0) {
            // this.$message({
            //   message: '密码重置成功,请重新登录',
            //   type: 'success'
            // });
            this.$EventBus.$emit("toast", {
              type: "success",
              message: "密码重置成功,请重新登录",
            });
            setTimeout(() => {
              this.pageType = "login";
            }, 1000);
          } else {
            // this.$message.error(res.msg);
            this.$EventBus.$emit("toast", {
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch((error) => {
          console.log("手机验证码找回密码", error);
        });
    },

    // 微信登录获取code
    wechatGetCode() {
      // 判断打开页面的设备是PC还是移动端
      if (this.browserRedirect()) {
        // alert("移动端微信授权")
        // this.$store.commit("accountAuthorization");
        this.$store.commit("accountAuthorization", "/index")
      } else {
        this.$store.commit("pcAuthorization");
      }
      localStorage.setItem("loginFlag", "wechat");
    },

    // 微信登录
    wechatLogin(loginType) {
      // console.log("登录传递的loginType", loginType);
      this.$request({
        method: "post",
        url: this.$requestPath.login,
        data: {
          code: this.code,
          loginType,
        },
      })
        .then((res) => {
          // console.log("微信登录", res);
          if (res.code == 0) {
            this.afterLogin(res.data);
          } else {
            this.$EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("微信登录error", error);
        });
    },

    afterLogin(data) {
      this.$EventBus.$emit("toast", {
        type: "success",
        message: "登录成功",
      });
      // 存储登录用户信息
      localStorage.setItem("LOGIN_INFO", JSON.stringify(data));
      this.$store.commit("updateLoginStatus", true); // 更新登录状态
      // 请求用户信息
      this.$store.dispatch("aquireUserInfo", () => {
        this.$store.dispatch("chat/getUserInfoByAccount", {
          callback: () => {
            this.$store.dispatch("chat/createSocket");
            this.$EventBus.$emit("dealIM");
          },
        });
      });
      this.$store.dispatch("getAutomaticReply")
      setTimeout(() => {
        this.$router.replace({ path: "/index" });
      }, 1000);
    },

    // qq登录获取code
    qqLogin() {
      localStorage.setItem("loginFlag", "qq");
      let redirect_uri = encodeURIComponent(
        `${process.env.VUE_APP_CALLBACK_URL}/login`
      );
      window.location.href = `https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=${process.env.VUE_APP_APPID}&redirect_uri=${redirect_uri}&state=a1105&scope=snsapi_userinfo`;

      // window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_login&state=3d6be0a4035d839573b04816624a415e#wechat_redirect`;
    },

    // qq登录获取token
    getQqToken() {
      let redirect_uri = encodeURIComponent(
        `${process.env.VUE_APP_CALLBACK_URL}/login`
      );
      // axios.get(`https://graph.qq.com/oauth2.0/token?grant_type=authorization_code&client_id=${process.env.VUE_APP_APPID}&client_secret=${process.env.VUE_APP_APPKEY}&code=${this.code}&state=a1105&redirect_uri=${redirect_uri}`).then(res => {
      //   console.log(res)
      // })
      // window.location.href = `https://graph.qq.com/oauth2.0/token?grant_type=authorization_code&client_id=${process.env.VUE_APP_APPID}&client_secret=${process.env.VUE_APP_APPKEY}&code=${this.code}&state=a1105&redirect_uri=${redirect_uri}`
      window.location.href = `https://graph.qq.com/oauth2.0/token?client_id=${process.env.VUE_APP_APPID}&client_secret=${process.env.VUE_APP_APPKEY}&code=${this.code}&grant_type=authorization_code&redirect_uri=${redirect_uri}`;
    },
    // qq登录发送后台请求
    qqLoginFunc(token) {
      console.log("登录传递的token", token);
      this.$request({
        method: "post",
        url: this.$requestPath.login,
        data: {
          qqLoginAccessToken: token,
          loginType: 5,
        },
      })
        .then((res) => {
          // console.log("qq登录", res);
          if (res.code == 0) {
            this.afterLogin(res.data);
          } else {
            this.$EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("微信登录error", error);
        });
    },

    // 跳转到协议页面
    goAgreement(index) {
      this.$router.push({
        path: "/index/agreement",
        query: {
          code: this.agreement[index],
        },
      });
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      console.log(ua); //mozilla/5.0 (iphone; cpu iphone os 9_1 like mac os x) applewebkit/601.1.46 (khtml, like gecko)version/9.0 mobile/13b143 safari/601.1
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  user-select: none;
}

.input_box {
  border: 0.0625rem solid #ccc;
  height: 2.1875rem;
  // width: 6.25rem;
}

.btn {
  // width: 12.5rem;
  height: 2.1875rem;
}

.vail {
  border: 0.0625rem solid var(--primary);
  width: 5.625rem;
  height: 2.1875rem;
  top: -0.0625rem;
}

@media screen and (max-width: 640px) {
  .login {

    // background: url("../../assets/images/login/phone_bg.png") no-repeat;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 7.5rem;
        height: 100%;
      }
    }

    .login_box {
      width: 90%;
    }

    .form_title {
      font-size: 1rem;
    }

    .btn {
      font-size: 0.9375rem;
    }

    .way_img {
      width: 1.375rem;
      height: 100%;
    }
  }
}

@media screen and (min-width: 640px) {
  .login {
    // background: url("../../assets/images/login/pc_bg.png") no-repeat;

    .logo {
      img {
        width: 11.25rem;
        height: 100%;
      }
    }

    .login_box {
      width: 37.5rem;
    }

    .way_img {
      width: 1.5rem;
      height: 100%;
    }
  }
}

.login {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  -o-background-size: cover !important;
  background-position: center center;

  .inner_box {
    padding: 1.25rem;
  }
}

.active {
  color: var(--primary);
}

.select_box {
  width: 2.5rem;
  height: 2.1875rem;
  border: 0.0625rem solid #ccc;
}

.none_border {
  border-right: none;
}

.select_box_active {
  border: 0.0625rem solid var(--primary);
}
</style>
<style lang="scss">
.login .form_input {
  width: 100%;

  .el-input {
    width: 100%;

    input {
      height: 2.1875rem !important;
      width: 100%;
    }
  }
}
</style>
